import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React, { Suspense } from 'react';
import * as serviceWorker from './serviceWorker';
import {
    getApplicationRoot,
    isCordovaApplication,
    needsiOSRtcFallback
} from './env';
import * as pwa from './pwa';
import { LicenseInfo } from '@mui/x-data-grid-pro';

declare global {
    type InAppBroderChannel =
        | 'loadstart'
        | 'loadstop'
        | 'loaderror'
        | 'exit'
        | 'message'
        | 'customscheme';

    /**
     * The object returned from a call to cordova.InAppBrowser.open.
     * NOTE: The InAppBrowser window behaves like a standard web browser, and can't access Cordova APIs.
     */
    interface InAppBrowser {
        /**
         * Opens a URL in a new InAppBrowser instance, the current browser instance, or the system browser.
         * @param  url     The URL to load.
         * @param  target  The target in which to load the URL, an optional parameter that defaults to _self.
         * @param  options Options for the InAppBrowser. Optional, defaulting to: location=yes.
         *                 The options string must not contain any blank space, and each feature's
         *                 name/value pairs must be separated by a comma. Feature names are case insensitive.
         */
        open(url: string, target?: string, options?: string): InAppBrowser;

        onloadstart(type: Event): void;
        onloadstop(type: InAppBrowserEvent): void;
        onloaderror(type: InAppBrowserEvent): void;
        onexit(type: InAppBrowserEvent): void;
        // addEventListener overloads
        /**
         * Adds a listener for an event from the InAppBrowser.
         * @param type      loadstart: event fires when the InAppBrowser starts to load a URL.
         *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
         *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
         *                  exit: event fires when the InAppBrowser window is closed.
         * @param callback  the function that executes when the event fires. The function is
         *                  passed an InAppBrowserEvent object as a parameter.
         */
        addEventListener(
            type: InAppBroderChannel,
            callback: InAppBrowserEventListenerOrEventListenerObject
        ): void;
        /**
         * Adds a listener for an event from the InAppBrowser.
         * @param type      any custom event that might occur.
         * @param callback  the function that executes when the event fires. The function is
         *                  passed an InAppBrowserEvent object as a parameter.
         */
        addEventListener(
            type: string,
            callback: InAppBrowserEventListenerOrEventListenerObject
        ): void;
        // removeEventListener overloads
        /**
         * Removes a listener for an event from the InAppBrowser.
         * @param type      The event to stop listening for.
         *                  loadstart: event fires when the InAppBrowser starts to load a URL.
         *                  loadstop: event fires when the InAppBrowser finishes loading a URL.
         *                  loaderror: event fires when the InAppBrowser encounters an error when loading a URL.
         *                  exit: event fires when the InAppBrowser window is closed.
         * @param callback  the function that executes when the event fires. The function is
         *                  passed an InAppBrowserEvent object as a parameter.
         */
        removeEventListener(
            type: InAppBroderChannel,
            callback: InAppBrowserEventListenerOrEventListenerObject
        ): void;
        /** Closes the InAppBrowser window. */
        close(): void;
        /** Hides the InAppBrowser window. Calling this has no effect if the InAppBrowser was already hidden. */
        hide(): void;
        /**
         * Displays an InAppBrowser window that was opened hidden. Calling this has no effect
         * if the InAppBrowser was already visible.
         */
        show(): void;
        /**
         * Injects JavaScript code into the InAppBrowser window.
         * @param script    Details of the script to run, specifying either a file or code key.
         * @param callback  The function that executes after the JavaScript code is injected.
         *                  If the injected script is of type code, the callback executes with
         *                  a single parameter, which is the return value of the script, wrapped in an Array.
         *                  For multi-line scripts, this is the return value of the last statement,
         *                  or the last expression evaluated.
         */
        executeScript(
            script: { code: string } | { file: string },
            callback: (result: any) => void
        ): void;
        /**
         * Injects CSS into the InAppBrowser window.
         * @param css       Details of the script to run, specifying either a file or code key.
         * @param callback  The function that executes after the CSS is injected.
         */
        insertCSS(
            css: { code: string } | { file: string },
            callback: () => void
        ): void;
    }

    type InAppBrowserEventListenerOrEventListenerObject =
        | InAppBrowserEventListener
        | InAppBrowserEventListenerObject;

    type InAppBrowserEventListener = (evt: InAppBrowserEvent) => void;

    interface InAppBrowserEventListenerObject {
        handleEvent(evt: InAppBrowserEvent): void;
    }

    interface InAppBrowserEvent extends Event {
        /** the eventname, either loadstart, loadstop, loaderror, or exit. */
        type: string;
        /** the URL that was loaded. */
        url: string;
        /** the error code, only in the case of loaderror. */
        code: number;
        /** the error message, only in the case of loaderror. */
        message: string;
    }

    interface CordovaDevice {
        cordova: string;
        model: string;
        platform: string;
        uuid: string;
        version: string;
        manufacturer: string;
        isVirtual: boolean;
        serial: string;
    }

    interface BarcodeScannerDimensions {
        position: { x: number; y: number };
        size: { w: number; h: number };
    }

    type BarcodeScannerFormat =
        | 'Code128'
        | 'Code39'
        | 'Code93'
        | 'CodaBar'
        | 'DataMatrix'
        | 'EAN13'
        | 'EAN8'
        | 'ITF'
        | 'QRCode'
        | 'UPCA'
        | 'UPCE'
        | 'PDF417'
        | 'Aztec';

    interface BarcodeScannerResult {
        text: string;
        format: BarcodeScannerFormat;
        type?: string;
    }

    interface BarcodeScannerOptions extends BarcodeScannerDimensions {
        detectorSize?: number;
        clearBackground?: boolean;
        behindWebView?: boolean;
        types?: Record<BarcodeScannerFormat, boolean>;
        scanDelay?: number;
    }

    interface CordovaPlugins {
        iosrtc: {
            registerGlobals(): void;
        };
        mlkit: {
            barcodeScanner: {
                start(
                    options: BarcodeScannerOptions,
                    onResult: (result: BarcodeScannerResult) => void,
                    onError: (message: string) => void
                ): void;

                toggleFlashlight(
                    onSuccess: (message: string) => void,
                    onError: (message: string) => void
                ): void;

                changeDimensions(
                    dimensions: BarcodeScannerDimensions,
                    onSuccess: (message: string) => void,
                    onError: (message: string) => void
                ): void;

                stop(
                    onSuccess: () => void,
                    onError: (message: string) => void
                ): void;
            };
        };
    }
    interface Cordova {
        plugins: CordovaPlugins;
        InAppBrowser?: InAppBrowser;
    }
    interface Window {
        cordova?: Cordova;
        device?: CordovaDevice;
    }
}

LicenseInfo.setLicenseKey(
    'f87dd545060035fdb945117d52421eafT1JERVI6NDExOTMsRVhQSVJZPTE2ODA3NzE0NDEwMDAsS0VZVkVSU0lPTj0x'
);

window.addEventListener('beforeinstallprompt', (evt) => {
    evt.preventDefault();
});

const renderApp = async (): Promise<void> => {
    const ReactDOM = await import('react-dom');
    const Provider = React.lazy(() => import('./Provider'));
    const App = React.lazy(() => import('./App'));

    if (window.location.href.indexOf('#') < 0) {
        window.location.href = window.location.href + '#';
    }

    const root = getApplicationRoot();

    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={null}>
                <Provider>
                    <App />
                </Provider>
            </Suspense>
        </React.StrictMode>,
        root
    );
};

if (isCordovaApplication()) {
    // Ensure cordova is ready, if we are running the app
    document.addEventListener('deviceready', () => {
        if (needsiOSRtcFallback()) {
            (window.cordova as Cordova).plugins.iosrtc.registerGlobals();
        }

        renderApp();
    });
} else {
    serviceWorker.register({
        onUpdate: () => {
            alert(
                'Eine Aktualisierung ist verfügbar. Bitte starten Sie den Browser bzw. die Anwendung erneut.'
            );
        }
    });

    pwa.register();
    renderApp();
}
