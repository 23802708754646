export const isStandaloneApplication = (): boolean =>
    window.matchMedia('(display-mode: standalone)').matches || // chrome, android, etc.
    (window.navigator as any).standalone; // safari only

export const isCordovaApplication = (): boolean => window.cordova != null;

export const isPlatform = (platform: string): boolean =>
    window.device != null && window.device.platform === platform;

export const isCordovaAndroid = (): boolean => isPlatform('Android');

export const isCordovaiOS = (): boolean => isPlatform('iOS');

export const isiOS = (): boolean => {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};

export const getiOSVersion = (): number => {
    const agent = window.navigator.userAgent;
    const start = agent.indexOf('OS ');
    if (
        (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
        start > -1
    ) {
        return window.Number(agent.substr(start + 3, 3).replace('_', '.'));
    }
    return 0;
};

export const getApplicationRoot = (): HTMLDivElement =>
    document.getElementById(
        process.env.REACT_APP_NAME + '-root'
    ) as HTMLDivElement;

export const supportsFullscreen = (): boolean => !isCordovaApplication();

export const isCordovaPermissionPluginLoaded = (): boolean =>
    isCordovaApplication() &&
    (window.cordova as Cordova).plugins != null &&
    (window.cordova as Cordova).plugins.permissions != null;

export const isCordovaInAppBrowserPluginLoaded = (): boolean =>
    isCordovaApplication() && (window.cordova as Cordova).InAppBrowser != null;

export const isCordovaBarcodeScannerPluginLoaded = (): boolean =>
    isCordovaApplication() &&
    (window.cordova as Cordova).plugins != null &&
    (window.cordova as Cordova).plugins.mlkit != null &&
    (window.cordova as Cordova).plugins.mlkit.barcodeScanner != null;

export const canUseBarcodeScannerPlugin = (): boolean =>
    isCordovaBarcodeScannerPluginLoaded();

export const isiOSRtcPluginLoaded = (): boolean =>
    isCordovaApplication() &&
    (window.cordova as Cordova).plugins != null &&
    (window.cordova as Cordova).plugins.iosrtc != null;

export const needsiOSRtcFallback = (): boolean => false;
// isCordovaiOS() && isiOSRtcPluginLoaded();

export const supportsDeviceChangeEvent = (): boolean =>
    navigator.mediaDevices != null &&
    navigator.mediaDevices.addEventListener != null &&
    navigator.mediaDevices.removeEventListener != null;

export const supportsMediaDevices = (): boolean =>
    navigator.mediaDevices != null &&
    navigator.mediaDevices.enumerateDevices != null;

export const supportsCordovaPermissionsPlugin = (): boolean =>
    isCordovaPermissionPluginLoaded() && isCordovaAndroid();

export const isSupportedDevice = (): boolean =>
    isCordovaApplication() ||
    (supportsMediaDevices() && (!isiOS() || getiOSVersion() > 12));
